import React, { useEffect, useState } from "react"
import { Carousel, Col, Row } from "react-bootstrap"
import { FaStar } from "react-icons/fa"
import { FaUserAlt } from "react-icons/fa"

function emptyCardRender() {
  return (
    <Carousel.Item>
      <Row className="justify-content-center mb-5">
        <Col xs={12} className="mb-5 mb-md-0" style={{ maxWidth: 900 }}>
          <blockquote
            className="blockquote mb-0 d-flex border border-secondary rounded p-4 loading-box-animate"
            style={{ height: 174, opacity: 0.5 }}
          ></blockquote>
        </Col>
      </Row>
    </Carousel.Item>
  )
}

function generateStars(rating = 3) {
  let stars = []
  for (let x = 0; x < 5; x++) {
    if (x < rating) {
      stars.push(
        <span key={x} style={{ color: "#D32323" }}>
          <FaStar size={20} />
        </span>
      )
    } else {
      stars.push(
        <span key={x} style={{ color: "#efefef" }}>
          <FaStar size={20} />
        </span>
      )
    }
  }
  return stars
}

function Testimonials() {
  const [testimonials, setTestimonials] = useState([])

  useEffect(function loadTestimonials() {
    fetch("/.netlify/functions/reviews")
      .then(resp => resp.json())
      .then(body => setTestimonials(body))
      .catch(e => alert("Error loading testimonials"))
  }, [])

  return (
    <Carousel id="testimonials" controls={false}>
      {testimonials.length === 0 && emptyCardRender()}
      {testimonials.map(testimonial => (
        <Carousel.Item key={testimonial.id}>
          <Row className="justify-content-center mb-5">
            <Col xs={12} className="mb-5 mb-md-0" style={{ maxWidth: 900 }}>
              <a
                href={testimonial.url}
                target="_blank"
                className="text-dark text-decoration-none"
              >
                <blockquote className="blockquote mb-0 d-flex flex-md-row flex-column align-items-center align-items-md-start border border-secondary rounded p-4">
                  <div style={{ width: 100, height: 100 }}>
                    {testimonial.user.image_url ? (
                      <img
                        src={testimonial.user.image_url}
                        alt={testimonial.user.name}
                        style={{ width: 100, height: 100 }}
                        className="border rounded-circle mb-3"
                      />
                    ) : (
                      <div
                        className="border rounded-circle mb-3 d-flex overflow-hidden justify-content-center align-items-center"
                        style={{ color: "#e3e3e3" }}
                      >
                        <FaUserAlt style={{ width: 100, height: 100 }} />
                      </div>
                    )}
                  </div>
                  <div className="ml-4">
                    <p className="mb-0">{testimonial.text}</p>
                    <div className="d-flex justify-content-between">
                      <div>{generateStars(testimonial.rating)}</div>
                      <footer className="blockquote-footer">
                        {testimonial.user.name}
                      </footer>
                    </div>
                  </div>
                </blockquote>
              </a>
            </Col>
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default Testimonials
