const serviceAreas = [
  "Antelope",
  "Arden-Arcade",
  "Carmichael",
  "Citrus Heights",
  "Davis",
  "Elk Grove",
  "El Dorado Hills",
  "Fair Oaks",
  "Folsom",
  "Granite Bay",
  "Lincoln",
  "Natomas",
  "North Highlands",
  "Rancho Cordova",
  "Rio Linda",
  "Rocklin",
  "Rosemont",
  "Roseville",
  "Sacramento",
  "West Sacramento",
  "Woodland",
]

export default serviceAreas
