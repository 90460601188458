import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Parallax } from "react-scroll-parallax"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { FaPhone, FaYelp, FaStar } from "react-icons/fa"
import companyInfo from "../data/companyInfo"

import MainLayout from "../layouts"
import Testimonials from "../components/Testimonials"
import serviceAreas from "../data/serviceAreas"
import ServiceCards from "../ServiceCards"
import { useFormSubmit } from "../hooks"

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sectionCoverImage: file(relativePath: { eq: "locksmith-cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInVanImage: file(relativePath: { eq: "leo-in-van.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInValWorking: file(relativePath: { eq: "leo-in-van-working.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keysImage: file(relativePath: { eq: "keys.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function freeQuoteSectionRender(className, submitForm, loading) {
  return (
    <section id="free-quote-section" className={className}>
      <Container fluid id="free-quote-container">
        <h2 className="h2-xs h2-lg">
          Get Your <span className="text-primary">Free</span> Quote Now
        </h2>
        <Form
          onSubmit={e => {
            e.preventDefault()
            const name = e.target.elements.name.value
            const email = e.target.elements.email.value
            const phone = e.target.elements.phone.value
            const comment = e.target.elements.message.value
            submitForm({ name, phone, email, comment })
          }}
        >
          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-md-0">
                <Form.Control placeholder="Name" name="name" />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-md-0">
                <Form.Control placeholder="Email" type="email" name="email" />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-md-0">
                <Form.Control placeholder="Phone" name="phone" />
              </Form.Group>
            </Col>
            <Col xs={12} md={8} className="mt-md-2">
              <Form.Group className="mb-md-0">
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Message"
                  name="message"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="mt-md-2">
              <Button
                variant="primary"
                type="submit"
                block
                disabled={loading}
                className="h-md-100"
              >
                Submit
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </section>
  )
}

const IndexPage = ({ data }) => {
  const [submitForm, loading] = useFormSubmit()

  return (
    <MainLayout
      title="Sacramento Mobile Locksmith | 24/7 Locksmith Near Me"
      description="Affordable home, car, & commercial locksmith services in Sacramento, CA. 916-995-0135.  Emergency lockouts, rekeys, lock repairs, ignition key replacement."
    >
      <section id="hero" className="pt-0 mb-md-5">
        <Parallax y={[-20, 20]} className="hero-image">
          <Img
            fluid={data.heroImage.childImageSharp.fluid}
            alt="hero-image"
            style={{ width: "100%", minHeight: 600 }}
          />
        </Parallax>
        <section id="hero-heading">
          <Container className="text-center text-light">
            <h1 className="h2 display-4-lg">
              Professional & Affordable Locksmith in Sacramento
            </h1>
            <p className="h3-xs h2-lg font-weight-light pb-xl-3">
              Offering 24/7 Mobile Locksmith Services
            </p>
            <Row className="mt-5">
              <Col
                xs={12}
                md={6}
                className="h2-lg h4-xs font-weight-light mb-4"
              >
                <span className="mr-3">
                  <FaPhone size={35} />
                </span>
                {companyInfo.phone}
              </Col>
              <Col xs={12} md={6}>
                <div className="d-inline-flex">
                  <span>
                    <FaYelp size={40} className="mr-3" />
                  </span>
                  <div className="h4-xs h2-lg font-weight-light">
                    Customers Love Us
                    <div className="d-flex justify-content-center">
                      <FaStar size={20} />
                      <FaStar size={20} />
                      <FaStar size={20} />
                      <FaStar size={20} />
                      <FaStar size={20} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*{freeQuoteSectionRender("d-none d-md-block", submitForm, loading)}*/}
      </section>
      {freeQuoteSectionRender("mb-5", submitForm, loading)}
      <section className="bg-white">
        <ServiceCards />
      </section>
      <section className="bg-white">
        <Container>
          <h1 className="text-center h2">Sacramento's 24/7 Locksmith</h1>
          <p className="indent">
            Welcome to Swift Locksmith, locally owned and operated since 2005.
            Our company provides residential, commercial and automotive services
            in the{" "}
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Swift+Locksmith/@38.5714138,-122.3349579,8z/data=!4m8!1m2!2m1!1sswift+locksmith+sacramento+ca!3m4!1s0x809ada351aa29d95:0x21dc7ef600b5a871!8m2!3d38.5658219!4d-121.365292"
            >
              Sacramento area
            </a>
            . Our goal is to offer advanced security solutions which are
            customized to meet each and every client’s individual security
            needs. We take pride in our prompt, reliable service and we will
            give each client our full attention.
          </p>
          <p className="indent mb-0">
            Because we are a local small business, you will not have a Mr. John
            Doe show up to your door who is from another other random company
            but you will have Leo, the owner, or one of his employees who are
            all certified and background checked to show up and provide you with
            a professional level of service. From helping you get into your
            locked vehicle or home to consulting you on how to make your
            property more secure, we have the tools and resources needed to
            resolve your matter in a timely fashion and are here to help you
            stay safe.
          </p>
        </Container>
      </section>
      <section>
        <div className="position-relative overflow-hidden background-content pb-0 pb-md-6">
          <Parallax
            y={[-20, 10]}
            className="workmanship-background"
            styleInner={{ height: "100%" }}
          >
            <BackgroundImage
              fluid={data.sectionCoverImage.childImageSharp.fluid}
              style={{ height: "120%" }}
            />
          </Parallax>
          <div
            style={{
              backgroundColor: "#333",
              opacity: 0.8,
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 1,
            }}
          />
          <Container style={{ zIndex: 2 }} className="position-relative">
            <Row className="text-light">
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 6, order: 1 }}
                className="mb-5 col-sm-pad"
              >
                <Img
                  fluid={data.locksmithWithCarImage.childImageSharp.fluid}
                  alt="locksmith-with-car"
                />
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 6, order: 2 }}
                className="mb-md-5"
              >
                <h2 className="text-primary">Quality Workmanship</h2>
                <p>
                  Our company is licensed, insured and available 24 hours a day,
                  7 days a week. Our{" "}
                  <a
                    href="http://swift-locksmith.mystrikingly.com"
                    target="_blank"
                  >
                    professional locksmiths
                  </a>{" "}
                  can provide you with a wide range of expert security
                  solutions. Any one of our locksmiths who arrive at your home,
                  business or car have all passed a background check, are highly
                  trained and have years of experience in the field. We can
                  assure you can trust us with all of your security and safety
                  needs.
                </p>
                <p className="mb-md-0">
                  Whether you may have an emergency or not, our technicians
                  treat all of our calls with urgency to ensure that you will
                  get the highest quality level of service you expect and
                  deserve.
                </p>
              </Col>
              <Col xs={{ span: 12, order: 3 }} md={{ span: 6, order: 3 }}>
                <h2 className="text-primary">Competitive Pricing</h2>
                <p>
                  When it comes to value, we will give you a true answer about
                  the cost and an estimated time of arrival. Leo and his crew
                  are skilled technicians that will supply you with a quote
                  containing all charges and no hidden fees and we do not change
                  our rates after a job is completed.
                </p>
                <p className="mb-md-0">
                  We value our customers, whether you are a returning client or
                  a new client, and hope to exceed your expectations every time.
                  We are always available, day or night and are just a phone
                  call away.
                </p>
              </Col>
              <Col
                xs={{ span: 12, order: 4 }}
                md={{ span: 6, order: 4 }}
                className="col-sm-pad"
              >
                <Img
                  fluid={data.leoInValWorking.childImageSharp.fluid}
                  alt="leo-in-van-working"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="bg-white">
        <Testimonials />
      </Container>
      <section>
        <div className="bg-dark text-white">
          <Container>
            <Row>
              <Col xs={12} lg={6} className="background-content">
                <h2>Service Area</h2>
                <div className="d-inline-flex">
                  <Row as="ul" style={{ marginBottom: "-0.5rem" }}>
                    {serviceAreas.map(serviceArea => (
                      <Col
                        xs={6}
                        sm={4}
                        lg={6}
                        as="li"
                        key={serviceArea}
                        className="mb-2"
                      >
                        <Link
                          style={{ color: "white" }}
                          to={
                            serviceArea !== "Sacramento"
                              ? `/city/${serviceArea
                                  .toLowerCase()
                                  .replace(/\s/g, "-")}`
                              : "/"
                          }
                        >
                          {serviceArea}
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-block overflow-hidden">
                <img
                  src="https://maps.googleapis.com/maps/api/staticmap?center=Sacramento,CA&zoom=10&size=540x540&key=AIzaSyAF3o7wsvExLFGAzsJuMW6GOBblJSXNohs"
                  alt="map"
                  className="h-100"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="bg-white">
        <h2>Most Trusted Locksmith Service</h2>
        <p className="indent">
          Swift Locksmith is a full-service mobile locksmith business that has
          been around for over 10 years. We service Sacramento and the
          surrounding areas and come to you for any service you might need even
          if it’s to make a key copy. Our vehicles are fully equipped to repair,
          install, or service any locksmith needs you might have.
        </p>
        <p className="indent mb-0">
          We’ve earned our reputation as being honest, reliable, dependable and
          customers who use our services always walk away satisfied. Because we
          take pride in our customer service, be sure to check out our Yelp
          reviews by some of our customers and if you have received service
          yourself, please leave an honest review on our{" "}
          <a
            href="https://www.yelp.com/biz/swift-locksmiths-sacramento"
            target="_blank"
          >
            Yelp page
          </a>
          . Thank you for entrusting and giving us a chance.
        </p>
      </Container>
      <Container as="section" className="mb-5 mb-md-0">
        <h2>24 Hour Emergency Services</h2>
        <p className="indent">
          We specialize in emergency lockout situations, so our trained
          locksmiths can rekey, repair or replace any locks at any given time,
          day or night. Our goal is to help you as quickly and efficiently as
          possible so that you can get back to your day or night and that is why
          we are available 24/7.
        </p>
        <p className="indent mb-0">
          Situations such as being locked out of your car or home or losing your
          office key can be frustrating and unexpected so we want to take the
          stress off you and take care of the situation in a timely manner. All
          of our locksmith technicians are trained to respond immediately to a
          call so if you find yourself in similar unpleasant situation, give us
          a ring and we will be more than happy to help!
        </p>
      </Container>
      <section className="d-none d-md-block pb-0">
        <Row noGutters>
          <Col>
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
            />
          </Col>
          <Col>
            <Img
              fluid={data.leoInVanImage.childImageSharp.fluid}
              alt="leo-in-van"
            />
          </Col>
          <Col>
            <Img fluid={data.keysImage.childImageSharp.fluid} alt="keys" />
          </Col>
          <Col>
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
            />
          </Col>
        </Row>
      </section>
    </MainLayout>
  )
}

export default IndexPage
